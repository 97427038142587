import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=bddced0e&scoped=true&"
import script from "./Contact.vue?vue&type=script&lang=js&"
export * from "./Contact.vue?vue&type=script&lang=js&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=bddced0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bddced0e",
  null
  
)


    import installComponents from "!/home/runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow})
    

export default component.exports