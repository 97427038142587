import { render, staticRenderFns } from "./User.vue?vue&type=template&id=0ac0934c&"
import script from "./User.vue?vue&type=script&lang=js&"
export * from "./User.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BCol, BFormCheckbox, BRow, BButton, BForm} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BCol, BFormCheckbox, BRow, BButton, BForm})
    

export default component.exports